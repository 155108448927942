<template>
  <v-container>
    <v-tabs
      hide-slider
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab @click="updateSize()">
        <v-icon left> mdi-face-woman </v-icon>
        Moi
      </v-tab>
      <v-tab @click="updateSize()">
        <v-icon left> mdi-badge-account-horizontal-outline </v-icon>
        Mes Expériences
      </v-tab>
      <v-tab @click="updateSize()">
        <v-icon left> mdi-lock </v-icon>
        Mes Diplômes
      </v-tab>
      <v-tab @click="updateSize()">
        <v-icon left> mdi-account-circle-outline </v-icon>
        À Propos
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-col cols="20">
            <v-card color="#73739F" dark>
              <v-card-title class="text-h5"> Centre d'interêt </v-card-title>

              <v-card-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  Sport
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  Natation (14 ans) Self-Défense (1an) Danse (1 an) Postural
                  Ball (1 an ) Pilate (1an)
                </v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  Voyage à l'Étranger
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  Espagne, Suisse, Portugal, Croatie, Autriche, Italie,
                  Allemagne
                </v-list-item-subtitle>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-card>
        <v-card flat>
          <v-col cols="20">
            <v-card color="#73739F" dark>
              <v-card-title class="text-h5"> Informatique </v-card-title>

              <v-card-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  MiVoice Business Mitel
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  - Outil d'administration et de configuration d'un PABX Mitel
                </v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  OMC Alcatel-Lucent
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  - Outil d'administration et de configuration d'un PABX Alcatel
                </v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  Cisco Packet Tracer
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  - Réalisation de schéma réseaux
                  <br />
                  - Test de configuration d'un réseau
                </v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  VirtualBox
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  - Création de machine virtuelle
                  <br />
                  - Gestion de plusieurs machines virtuelles
                  <br />
                  - Mise en réseau des machines virtuelles
                </v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  HTML et CSS
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  - Création de quelques sites web dans le cadre de mes études
                </v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  Windows
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  Windows serveur 2012, 2016
                  <br />
                  - AD, GPO
                </v-list-item-subtitle>
                <v-list-item-title class="text-h5 mb-1">
                  Linux
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  - Gestion du réseau, DNS, DHCP
                </v-list-item-subtitle>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
              v-for="(item, i) in experiences"
              :key="i"
              :color="item.color"
              :icon="item.icon"
              fill-dot
            >
              <v-card :color="item.color" dark>
                <v-card-title class="text-h6">
                  {{ item.titre }}
                </v-card-title>
                <v-card-subtitle>
                  {{ item.date }}
                </v-card-subtitle>
                <v-card-text class="white text--primary">
                  <p style="white-space: pre-wrap">{{ item.texte }}</p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
              v-for="(item, i) in diplomes"
              :key="i"
              :color="item.color"
              :icon="item.icon"
              fill-dot
            >
              <v-card :color="item.color" dark>
                <v-card-title class="text-h6">
                  {{ item.titre }}
                </v-card-title>
                <v-card-subtitle>
                  {{ item.date }}
                </v-card-subtitle>
                <v-card-text class="white text--primary">
                  <p style="white-space: pre-wrap">{{ item.texte }}</p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-col cols="20">
            <v-card color="#73739F" dark>
              <v-card-title class="text-h5" style="margin-bottom: 10px">
                <v-icon left> mdi-account-box-outline </v-icon>
                Contact
              </v-card-title>
              <v-card-subtitle>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  <v-icon left> mdi-at </v-icon>
                  justine.bretignol@gmail.com
                </v-list-item-subtitle>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  <v-icon left> mdi-cellphone </v-icon>
                  06 95 02 54 33
                </v-list-item-subtitle>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  <v-icon left> mdi-account-circle-outline </v-icon>
                  <a
                    style="color: rgba(255, 255, 255, 0.7)"
                    href="https://www.linkedin.com/in/justine-bretignol-28948a19b"
                    >Linkedin</a
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle style="white-space: pre-wrap">
                  <v-icon left> mdi-file-pdf-box </v-icon>
                  <a
                    style="color: rgba(255, 255, 255, 0.7)"
                    href="./CV_2023-01-17_Justine_Bretignol.pdf"
                    target="_blank"
                    >CV</a
                  >
                </v-list-item-subtitle>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorldMobile",

  methods: {
    updateSize() {
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 150);
    },
  },

  data: () => ({
    experiences: [
      {
        color: "orange lighten-2",
        icon: "mdi-lan",
        titre: "Administrateur Réseau",
        texte:
          "- Installation coeur de réseau chez les clients\n- Configuration des équipements réseaux \n- Support N2 \n- Schéma réseau \n- Marque: Huawei, HP, Aruba, Cisco, Alcatel \n- Hyperviseur: Imaster, Aruba central \n- Fortigate, Fortinet ",
        date: "De Septembre 2022 à Aujourd'hui",
      },
      {
        color: "red lighten-2",
        icon: "mdi-briefcase-outline",
        titre: "Expert Soutien technicien en Grand Public chez Orange",
        texte:
          "Alternante en Licence Proffessionnel Réseaux et Télécommunication \n- Réalisation d'une installation compléte fibre \n- Câblage cuivre/fibre \n- SAV sur installation cuivre/fibre",
        date: "Depuis Septembre 2021 à Âout 2022",
      },
      {
        color: "purple darken-1",
        icon: "mdi-phone-outline",
        titre: "Technicien d'Intervention en PABX chez Orange",
        texte:
          "Alternante en DUT Réseaux et Télécommunication: \n- Réalisation d'une installation compléte Alcatel/Mitel  \n- Câblage de borne DECT  \n- SAV sur installation Alcatel",
        date: "De Septembre 2020 à Âout 2021",
      },
      {
        color: "green lighten-1",
        icon: "mdi-horse",
        titre: "Agent d'accceuil et d'information au Salon du cheval à Angers",
        texte:
          "Présentation de nouvelles technologies dans le domaine de l'équitation",
        date: "Novembre 2017",
      },
      {
        color: "indigo",
        icon: "mdi-laptop",
        titre: "Développeur en Informatique Stagiaire dans la Société Octave",
        texte:
          "- Réalisation d'un projet avec une interface  \n- Réflechir à la conseption d'un programme \n- Créer un graphique",
        date: "Septembre 2015",
      },
    ],
    diplomes: [
      {
        color: "red lighten-2",
        icon: "mdi-school-outline",
        titre: "Licence Professionnelles Réseaux et Télécommunication",
        texte:
          "Alternante en Licence Proffessionnel Réseaux et Télécommunication",
        date: "Depuis Septembre 2021 à Angers",
      },
      {
        color: "pink darken-1",
        icon: "mdi-school-outline",
        titre: "MOOC",
        texte:
          "MOOC de l'ANSSI \n- Initiation à la cybersécurité \n- Sensibilisation au RGPD (Règlement Général sur la Protection des Données)",
        date: "Décembre 2021",
      },
      {
        color: "green darken-1",
        icon: "mdi-school-outline",
        titre: "CCNA2",
        texte:
          "- Effectuer une configuration de base du réseau et un dépannage, \n- Identifier et d'atténuer les menaces à la sécurité du réseau local \n- Configurer et de sécuriser un réseau local sans fil de base \n- Mise en œuvre des VLAN \n- Mise en œuvre du DHCP \n- Mise en œuvre du DNS",
        date: "De septembre 2021 à février 2022",
      },
      {
        color: "purple darken-1",
        icon: "mdi-school-outline",
        titre: "CCNA1",
        texte:
          "- Création de LAN simple \n- Configuration simple de routeur et de commutateur \n- Implémenter des schémas d'adressage IP \n- Mise en oeuvre du Routage",
        date: "De septembre 2021 à février 2022",
      },

      {
        color: "red darken-1",
        icon: "mdi-school-outline",
        titre: "DUT Réseaux et Télécommunication",
        texte: "Alternante en deuxième année",
        date: "De Septembre 2019 à Âout 2021 à La Roche Sur Yon",
      },
      {
        color: "orange lighten-1",
        icon: "mdi-school-outline",
        titre: "BAC Technoologique STI2D option SIN",
        texte:
          "BAC Sciences et Technologie de l’Industrie du Développement Durable Lycée Chevrollier",
        date: "De Septembre 2016 à Âout 2018 à Angers",
      },
    ],
  }),
};
</script>