<template>
  <v-app>
    <div>
      <v-toolbar dark prominent>

        <v-img alt="Brebond Logo" class="shrink" contain :src="require('./assets/IMG_2227.webp')" width="100"> </v-img>
        <v-toolbar-title>Justine Bretignol</v-toolbar-title>

        <v-spacer></v-spacer>


      </v-toolbar>
    </div>
    <v-main>
      <vue-particles class="child1" color="#ffffff" :particleOpacity="0.7" linesColor="#ffffff" :particlesNumber="80"
        shapeType="circle" :particleSize="5" :linesWidth="2" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150"
        :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push">
      </vue-particles>
      <HelloWorld class="d-none d-md-flex" />
      <HelloWorldMobile class="d-block d-md-none" />
    </v-main>
    <v-footer dark padless>

      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>Justine Bretignol</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import HelloWorldMobile from './components/HelloWorldMobile';

export default {
  name: 'App',

  components: {
    HelloWorld,
    HelloWorldMobile
  },

  data: () => ({
    icons: [
      'mdi-linkedin',
    ],

  }),
};
</script>